import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Hello, we are FTNL', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hello, we are',
  name: 'FTNL',
  subtitle: 'We develop and manage projects and products',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.png',
  paragraphOne: 'We are a company based in France',
  paragraphTwo: 'We help our customers develop awesome projects and products',
  paragraphThree: 'We use the last technologies and methodologies',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project.png',
    title: 'Track CRM',
    info: 'Track is an easy-to-use, mobile first, CRM for creators, freelancers and small businesses',
    info2: '',
    url: 'https://www.track-app.io/',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Need help on a project or product? ',
  btn: 'Contact us!',
  email: 'contact {at symbol} ftnl.org',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/company/ftnl/',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
